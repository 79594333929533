/*
 * show and hide thin strip promo banner
 */
(function () {

	"use strict";

	// when user clicks the X, close the promo banner and hide it for one day
	function closeBanner () {

		// get all promo banners
		var promoBanners = [].slice.call(document.querySelectorAll(".js-promo-banner"));

		if (promoBanners) {
			promoBanners.forEach(function (promo, index) {
				// hide each banner
				promoBanners[index].classList.add("promo--hidden");
			});
		}

		// set a cookie to expire in 1 day (24 hours)
		var oneDayFromNow = new Date((new Date()).getTime() + (24 * 60 * 60 * 1000));
		document.cookie = [
			"qualys.promo.banner.notice=false",
			"path=/",
			"expires=" + oneDayFromNow.toUTCString(),
			"secure=yes"
		].join("; ");

	}

	// if the promo banner cookie doesn't exist, show one promo banner at a time
	if (!document.cookie.match(/qualys\.promo\.banner\.notice/)) {

		// get array of promo banners
		var promos = [].slice.call(document.querySelectorAll(".js-promo-banner"));

		// get client's current time in seconds
		var seconds = Math.round((Date.now() || (new Date()).getTime()) / 1000);

		// filter out expired promos
		promos = promos.filter(function (promo) {

			var expires = promo.getAttribute("data-expires");
			if (expires) {
				var expirationDate = new Date(expires);
				if (expirationDate && expirationDate > new Date()) {
					return promo;
				}
			}

		});

		// randomly get index of one promo banner to show
		var index = seconds % promos.length, folder, noBanner = false;

		// do not show banner for pages under certain folders
		var folders = ["whitepapers", "forms", "free-trial", "lp"];
		var pathname = window.location.pathname;

		for (folderIndex in folders) {
			if( pathname.indexOf(folders[folderIndex]) >= 0 ){
				noBanner = true;
				break;
			}
		}

		// show one promo banner
		if (index >= 0 && !noBanner) {
			promos[index].classList.remove("promo--hidden");
			var body = document.querySelector("body");
			body.insertBefore(promos[index], body.firstChild);
		}
	}

	// when user clicks to close the promo banner close button, call the closeBanner function
	var promoBannerCloseButtons = [].slice.call(document.querySelectorAll(".q-alert__close"));

	if (promoBannerCloseButtons) {
		promoBannerCloseButtons.forEach(function (button) {
			button.addEventListener("click", closeBanner);
		});
	}

}());